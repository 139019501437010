var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
//
import { CheckboxIcon, CheckboxCheckedIcon, CheckboxIndeterminateIcon } from './CustomIcons';
// ----------------------------------------------------------------------
export default function Checkbox(theme) {
    return {
        MuiCheckbox: {
            defaultProps: {
                icon: _jsx(CheckboxIcon, {}),
                checkedIcon: _jsx(CheckboxCheckedIcon, {}),
                indeterminateIcon: _jsx(CheckboxIndeterminateIcon, {}),
            },
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return (__assign(__assign({ padding: theme.spacing(1) }, (ownerState.size === 'small' && {
                        '& svg': { width: 20, height: 20 },
                    })), (ownerState.size === 'medium' && {
                        '& svg': { width: 24, height: 24 },
                    })));
                },
            },
        },
    };
}
